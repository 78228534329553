import Type from '../../type';
import { getApiController } from '..';
import { IResource } from '../../../../../shared/interfaces/common/resource';
import { ISearchBrands, ISearchNotifications } from '../../../../../presentation/redux/actions/interfaces/notification.types';

const searchNotifications = (payload: ISearchNotifications, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0?keyword=${payload.keyword}&status=${payload.status}&type=${payload.type ?? ''}&endDate=${payload.endDate}&startDate=${payload.startDate}&page=${payload.page}&pageSize=${payload.pageSize}&excludes=${payload.excludes ?? ''}&sakayaId=${payload.sakayaId ?? ''}`
});

const searchPriceChanges = (payload: ISearchNotifications, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0?keyword=${payload.keyword}&status=${payload.status}&type=4&endDate=${payload.endDate}&startDate=${payload.startDate}&page=${payload.page}&pageSize=${payload.pageSize}&excludes=${payload.excludes ?? ''}`
});

const SaveNotification = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/SaveNotification`
});

const SavePartialNotification = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/SavePartialNotification`
});

const RegisterPriceChanges = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/RegisterPriceChangesV2`
});
const PushNotification = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/PushNotification`
});

const SearchBrands = (payload: ISearchBrands, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/SearchBrands?keyword=${payload.keyword}&page=${payload.page}&pageSize=${payload.pageSize}`
});

const SearchBanners = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/SearchBanners?sakayaId=${payload.sakayaId ?? ''}`
});

const GetCategories = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/GetCategories`
});

const SearchTags = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/Tags?keyword=${payload.keyword}&page=${payload.page}&pageSize=${payload.pageSize}${payload.sortName ? '&sortName=' + payload.sortName :  ''}${payload.sortDate ? '&sortDate=' + payload.sortDate :  ''}`
});

const deleteTags = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/DeleteTags?ids=${payload.ids}`
});

const AddTagForSakaya = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/AddTagForSakaya`
});

const UpdateTagForSakaya = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/UpdateTagForSakaya`
});

const AddTagForCustomer = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/AddTagForCustomer`
});

const DeleteTagForCustomer = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/DeleteTagForCustomer?id=${payload.id}&customerId=${payload.customerId}`
});

const ImportCustomerTags = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/ImportCustomerTags`
});

const PopulateAddress = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/PopulateAddress?code=${payload.code}`
});

const GetOrderOfProduct = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/GetOrderOfProduct?id=${payload.id ?? ''}&priceIds=${payload.priceIds}&type=${payload.type}&cancleDraft=${payload.cancleDraft}`
});

const GetPriceChangeLines = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/GetPriceChangeLinesV2`
});

const UploadPriceNotification = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/UploadPriceNotification`
});

const ImportNotificationHistory = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/ImportNotificationHistory?page=${payload.page}&pageSize=${payload.pageSize}&type=${payload.type}&endDate=${payload.endDate}&startDate=${payload.startDate}&sakayaId=${payload.sakayaId ?? ''}&ignoreLogs=true`
});

const DownloadDraftToFile = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/DownloadDraftToFile?type=${payload.type}`
});
const DownloadNotification = (notificationId: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/DownloadCSVNotification?id=${notificationId}`
})
const GetDraftRecord = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/GetDraftRecord?type=${payload.type}&draftId=${payload.draftId ?? ''}`
});

const NotificationDetail = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/NotificationDetail?id=${payload.id}&checkStatus=${payload.checkStatus ?? false}`
});

const DeleteDraftRecord = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/DeleteDraftRecord?id=${payload.id}`
});

const DeleteNotifications = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/DeleteNotifications?ids=${payload.ids}`
});

const UpdateNotificationStatus = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/UpdateNotificationStatus?id=${payload.id}&status=${payload.status}`
});

const searchDiscontinued = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/discontinued/v1.0?keyword=${payload.keyword}&page=${payload.page}&pageSize=${payload.pageSize}&status=${payload.status ?? ''}&endDate=${payload.endDate}&startDate=${payload.startDate}&sakayaId=${payload.sakayaId ?? ''}&isAttention=true`
});

const searchReadyDiscontinued = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/discontinued/v1.0?keyword=${payload.keyword}&page=${payload.page}&pageSize=${payload.pageSize}&status=${payload.status ?? ''}&endDate=${payload.endDate}&startDate=${payload.startDate}&sakayaId=${payload.sakayaId ?? ''}&isAttention=false`
});

const GetDiscontinuedProducts = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/discontinued/v1.0/GetDiscontinuedProducts?id=${payload.id ?? ''}&priceIds=${payload.priceIds}&type=${payload.type}&cancleDraft=${payload.cancleDraft}&isUpload=${payload.isUpload ?? false}`
});

const HistoryDiscontinuedProducts = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/discontinued/v1.0/HistoryDiscontinuedProducts?id=${payload.id ?? ''}&keyword=${payload.keyword}`
});

const CountWarningProduct = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/discontinued/v1.0/CountWarningProduct?sakayaId=${payload?.sakayaId ?? ''}`
});

const CreateDiscontinuedProducts = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/discontinued/v1.0/CreateDiscontinuedProducts`
});

const UploadDiscontinuedProduct = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/discontinued/v1.0/UploadDiscontinuedProduct`
});

const UpdateDiscontinueLine = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/discontinued/v1.0/UpdateDiscontinueLine`
});

const DeleteDiscontinueLine = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/discontinued/v1.0/DeleteDiscontinueLine?ids=${payload.ids ?? ''}`
});

const SavePromotion = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/SavePromotion`
});

const CalculateAnnouncementTarget = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/CalculateAnnouncementTarget`
});

const DownloadDiscontinueLine = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/discontinued/v1.0/DownloadDiscontinueLine`
});

const SearchSegments = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/Segments?page=${payload.page}&pageSize=${payload.pageSize}&keyword=${payload.keyword}&sakayaId=${payload.sakayaId ?? ''}&hideFake=${payload.hideFake ?? false}${payload.ids ?? ''}`
});

const AddSegment = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/AddSegment`
});

const CalculateAudienceSize = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/CalculateAudienceSize`
});

const DeleteSegment = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/segments/v1.0/DeleteSegment?ids=${payload.ids}&sakayaId=${payload.sakayaId ?? ''}`
});

const SettingNotifications = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/CreateOrUpdateNotificationSetting`
});

const GetSettingNotifications = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/notification/v1.0/GetNotificationSetting`
});

export default {
    CountWarningProduct,
    SaveNotification,
    SavePartialNotification,
    RegisterPriceChanges,
    PushNotification,
    SearchBrands,
    SearchBanners,
    searchNotifications,
    searchPriceChanges,
    GetCategories,
    SearchTags,
    deleteTags,
    AddTagForSakaya,
    UpdateTagForSakaya,
    AddTagForCustomer,
    DeleteTagForCustomer,
    ImportCustomerTags,
    PopulateAddress,
    GetOrderOfProduct,
    GetPriceChangeLines,
    UploadPriceNotification,
    ImportNotificationHistory,
    DownloadDraftToFile,
    GetDraftRecord,
    NotificationDetail,
    DeleteDraftRecord,
    DeleteNotifications,
    UpdateNotificationStatus,
    searchDiscontinued,
    searchReadyDiscontinued,
    GetDiscontinuedProducts,
    HistoryDiscontinuedProducts,
    CreateDiscontinuedProducts,
    UploadDiscontinuedProduct,
    UpdateDiscontinueLine,
    DeleteDiscontinueLine,
    DownloadNotification,
    SavePromotion,
    CalculateAnnouncementTarget,
    DownloadDiscontinueLine,
    SearchSegments,
    AddSegment,
    CalculateAudienceSize,
    DeleteSegment,
    SettingNotifications,
    GetSettingNotifications,
};

